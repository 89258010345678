.rpb-blog-feed {
  //starter 
  &.feed-style-starter {
    color:$body-font;
  }
  // Style 1
  &.feed-style-1 {
    .blog-feed-slider {
      .splide__slider {
        .rpb-slider-arrows {
          display:flex;
          align-items:center;
          justify-content:center;
          margin-top:1rem;
          @include media-breakpoint-up(lg) {
            position:absolute;
            display:block;
            top:-100px;
            right:0;
            margin-top:0;
          }
          .splide__arrow {
            display:inline-block;
            position:relative;
            transform:none;
            height:50px;
            width:50px;
            border: 1 px solid rgba(0,0,0,.25);
            transition:all 350ms ease-in-out;
            &:hover {
              background:rgba(0,0,0,.05);
            }
            i,svg {
              color:#191919;
              display:flex;
              margin:0 auto;
              opacity:0.5;
            }
          }
          .splide__arrow--prev {
            left:auto;
            margin-right:5px;
            @include media-breakpoint-up(lg) {
              margin-right:0;
            }
          }
          .splide__arrow--next {
            right:auto;
            margin-left:5px;
            @include media-breakpoint-up(lg) {
              margin-left:0;
            }
          }
        }
      }
    }
    .blog-post-content-row {
      &.blog-post-content-row-hasimage {
        @include media-breakpoint-up(lg) {
          min-height: 325px;
        }
      }
      .post-featured-image {
        height: 100%;
        width: 100%;
        min-height:150px;
        background-size: cover;
        background-position: center;
        background-repeat: no-repeat;
        border-radius: 4px;
        position: relative;
      }
      .post-content {
        position: relative;
        display:flex;
        flex-direction:column;
        .post-content-inner  {
          background:white;
          padding:20px;
          @include media-breakpoint-up(lg) {
            background:transparent;
            padding: 0 1.5rem 0 .5rem;
            display:flex;
            flex-direction:column;
            height:100%;
          }
        }
        .blog-title {
          font-weight:bold;
        }

        .blog-excerpt {
          margin-top: 1rem;
          margin-bottom: 1rem;
          line-height: 1.5;
          opacity: 0.8;
          @include media-breakpoint-up(lg) {
            margin-top:0;
          }
          a {
            color:inherit;
            font-weight:400;
            &:hover {
              color:inherit;
            }
          }
        }
      }
    }
    .blog-cats {
      flex-wrap:wrap;
      height:auto;
    }
    .blog-cats span {
      border-radius: 50px;
      padding: 0.25em 0.75em 0.25em 0.75em;
      font-size: 13px!important;
      color: white;
      background:$dark;
      font-family:$subheading-font;
      text-transform: uppercase;
      font-weight:800;
    }
  }  
}
.page-numbers {
  background: $brand-primary;
  color:#fff;
  padding: 0.25em 0.5em;
  &:hover {
    background:$dark;
    color:#fff!important;
  }
}
