.rpb-cards {
  .card {
    border:solid 2px $accent;
    p {
      &:last-of-type {
        margin-bottom:0;
      }
    }
    .card-header {
      background:unset;
      border-bottom:unset;
    }
  }
  // Vertical Align Class
  .vertically-aligned-card {
    display:flex;
    flex-direction:column;
    justify-content:center;
  }
  // Image Classes
  .bg-img-card {
    display:none;
  }
  // Default Slider Styles
  &.bootstrap-cards-slider {
    .splide__slider {
      .rpb-slider-arrows {
        .splide__arrow--prev {
          left:0;
        }
        .splide__arrow--next {
          right:0;
        }
      }
      .splide__track {
        margin-left:3rem;
        margin-right:3rem;
      }
      .splide__pagination {
        bottom:-2.5rem;
      }
    }
    &.overflow-end {
      .splide__track {
        margin-right:0px;
        padding-right:4em!important;
        @include media-breakpoint-up(xl) {
          padding-right:6em!important;
        }
      }
    }
    &.arrows-bottom {
      margin-bottom: 2.25rem;
      .splide__arrow {
        font-size:1.5em;
        background:transparent!important;
        bottom:-6rem!important;
        top:auto;
        svg {
          color:$accent;
          fill:$accent;
        }
      }
      .splide__arrow--prev {
        left: calc(50% - 3em)!important;
        right: auto!important;
      }
      .splide__arrow--next {
        right: calc(50% - 3em)!important;
        left: auto!important;
      }
      .splide__track {
        margin-right:0px;
        margin-left:0px;
      }
    }
    &.arrows-end {
      margin-bottom: 2.25rem;
      .splide__arrow {
        font-size:1.5em;
        background:transparent!important;
        bottom:-6rem!important;
        top:auto;
        svg {
          color:$dark;
          fill:$dark;
        }
      }
      .splide__arrow--prev {
        left: calc(100% - 5em)!important;
        right: auto!important;
      }
      .splide__arrow--next {
        right: 0px!important;
        left: auto!important;
      }
      .splide__track {
        margin-right:0px;
        margin-left:0px;
      }
    }
  }
}
