// Theme Colors/Branding
$brand-primary: #515864;
$brand-primary-rgb: rgba($brand-primary,1);
$brand-primary-offset: darken($brand-primary,10%);
$brand-secondary: #f47622;
$dark: #3E4147;
$light: #f5f5f5;
$medium: #dee2e6;
$inactive: #8c8c8c;
$gray: #888;
$white: #fff;
$accent: rgb(86, 65, 245);
$accent-2: #1459BA;
$body-font: #222;
$error: #d81e00;

// Update BS colors
$primary: $brand-primary;
$secondary: $brand-secondary;
$info: $accent;