.rpb-accordion {
  .accordion-item {
    background-color: transparent;
    .accordion-body {
      background-color:transparent!important;
      p {
        &:last-of-type {
          margin-bottom:0;
        }
      }
    }
    .accordion-button{
      background-color: transparent!important;
      color: $brand-secondary;
    }
    .accordion-button::after{
      background-color: $brand-secondary;
      border-radius: 50px;
    }
    .accordion-button:not(.collapsed) {
      background-color:transparent!important;
      color:white;
      font-weight:bold;
    }
    .accordion-button:not(.collapsed)::after {
      background-image: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 16 16' fill='%23ffffff'%3E%3Cpath fill-rule='evenodd' d='M1.646 4.646a.5.5 0 0 1 .708 0L8 10.293l5.646-5.647a.5.5 0 0 1 .708.708l-6 6a.5.5 0 0 1-.708 0l-6-6a.5.5 0 0 1 0-.708z'/%3E%3C/svg%3E") !important;
      background-color: $brand-secondary;
      border-radius: 50px;
    }
  }
}